import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const KampanieNaInstagramiePage = ({ location: { pathname } }) => {
  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, 2550 / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = [
        "rozpoznawalności marki",
        "kampania na zasięg",
        "generowanie ruchu",
        "budowanie społeczności",
        "interakcje pod postami",
        "pozyskiwanie leadów",
        "wyświetlanie filmu",
        "promocja lokalizacji",
        "wysyłanie wiadomości",
        "generowanie sprzedaży",
        "pobranie aplikacji",
        "pozyskanie konwersji",
      ];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Kampanie reklamowe na Instagramie",
        description:
          "Dotrzyj do swoich klientów na Instagramie. Wykorzystaj możliwość zwiększania sprzedaży za pomocą Instagrama. Instagram Ads umożliwia dotarcie do konkretnych grup odbiorców, co pozwala na skuteczne promowanie produktów i usług. Realizuj z When kampanie na Instagramie. Skorzystaj z bezpłatnych konsultacji i planu działania kampanii Instagram dla Twojej firmy!",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampanie <br /> <span>na Instagramie</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie na Instagramie</a>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2 single-offer-section-text-left--gdn">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Marka <br /> w lifestylowym wydaniu
                </h2>
                <p className="text-col__desc">
                  Instagram powstał w 2010 roku z prostej potrzeby: dzielenia
                  się swoim codziennym życiem za pomocą zdjęć. 10 lat później
                  liczba użytkowników Instagrama na świecie przekracza zawrotną
                  liczbę miliarda miesięcznie, z czego 8,5 mln użytkowników w
                  samej Polsce. I choć początkowo w aplikacji można było znaleźć
                  głównie prywatne zdjęcia kawy, psów, kotów i selfie w lustrze,
                  tak ogromny sukces musiał przełożyć się na zainteresowanie
                  wykorzystaniem tego medium również w celach biznesowych.
                  Obecnie oprócz profili prywatnych na Instagramie odnajdziemy
                  tam również wiele firm, które traktują to źródło często jako
                  jeden z głównych kanałów komunikacji, przedstawiając swoje
                  usługi oraz produkty z mniej oficjalnej, lifestylowej strony.
                  Instagram to świetna przestrzeń głównie dla marek, które
                  posiadają produkty, które można ująć na ładnych, niebanalnych
                  zdjęciach lub chcą przedstawić swoją pracę oraz zespół od
                  kuchni, informując o bieżących wydarzeniach z życia firmy.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/kampanie-ig-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-5 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/kampanie-ig-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Dwa kanały, <br />
                  jeden Business Manager
                </h2>
                <p className="text-col__desc">
                  Zarówno reklamy facebookowe, jak i reklamy instagramowe, są
                  tworzone i zarządzane z jednego Business Managera. Z tego
                  powodu proces przygotowania kampanii jest jednakowy dla obu
                  platform. Co więcej - jedna kampania może być wyświetlana we
                  wszystkich kanałach: zarówno na Facebooku, Instagramie, w
                  Messengerze i w sieci odbiorców (Audience Network)
                  zintegrowanej z Facebookiem. Warto jednak zastanowić się nad
                  wydzieleniem reklam Instagramowych do oddzielnej kampanii.
                  Dlaczego? Po pierwsze najważniejszym przekazem reklamy na
                  Instagramie jest jej grafika (zdjęcie / wideo), sama treść
                  wpisu spada na dalszy plan z uwagi na charakterystykę portalu.
                  Po drugie - wskaźniki efektywności kampanii takie jak
                  kliknięcia, wyświetlenia czy koszt kampanii będzie inny dla
                  tych dwóch kanałów. Dodatkowo można w kampanii Instagramowej
                  wydzielić konkretne umiejscowienia - osobno przygotować zestaw
                  pod Instastories, które wymagają wizualizacji w formatach
                  wertykalnych, żeby zapełnić cały ekran urządzenia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-single-video">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-md-7 col-md-3 col-sm-8 offset-sm-4 single-offer-section-single-video__text-col">
                <h2 className="single-offer-section-single-video__title">
                  Przykład reklamy <br /> na Instagramie
                </h2>
                <p className="single-offer-section-single-video__desc">
                  Reklama z filmem utworzonym w managerze reklam ze zdjęć
                  klienta
                </p>
              </div>
            </div>
            <div className="video-container">
              <div className="video-container__video-radius">
                <img
                  src="https://when.zenx.pl/wp-content/themes/adream/assets/video/reklama-ig.gif"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-left single-offer-section-text-left--kampanie-na-ig">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-11 text-col">
                <h2 className="text-col__title mb-40">
                  Precyzyjne targetowanie
                </h2>
                <p className="text-col__desc">
                  Opcje targetowania kampanii Instagramowej są analogiczne do
                  kampanii Facebookowej: Możemy kierować reklamy na konkretne
                  lokalizacje geograficzne, osoby w określonym wieku, oraz
                  osoby, które były na naszej stronie lub weszły w interakcję ze
                  stroną na Facebooku lub profilem na Instagramie. Mamy do
                  dyspozycji również wiele szczegółowych zainteresowań
                  skategoryzowanych przez Facebooka.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--kampanie-na-ig">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-big-img__title">
              Targetuj reklamę <br /> na Instagramie po:
            </h2>
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/kampanie-ig-graph.png")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/kampanie-ig-graph-mobile.png")}
                alt=""
              />
              <p className="image-container__text text text--1">
                szczegółowych <br /> danych <br /> demograficznych
              </p>
              <p className="image-container__text text text--2">lokalizacji</p>
              <p className="image-container__text text text--3">
                zainteresowaniach <br /> i pasji użytkowników
              </p>
              <p className="image-container__text text text--4">
                listach <br /> remarketingowych
              </p>
              <ul className="big-bullets list-mobile">
                <li>szczegółowych danych demograficznych</li>
                <li>lokalizacji</li>
                <li>zainteresowaniach i pasji użytkowników</li>
                <li>listach remarketingowych</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text single-offer-section-text--kampanie-na-ig">
        <div className="row">
          <div className="col-lg-8 offset-lg-3 text-col">
            <h2 className="text-col__title">
              <span>
                Kampanie dopasowane <br /> do Twoich celów:
              </span>{" "}
              <br />
              <span id="shuffle">rozpoznawalność marki</span>
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right single-offer-section-text-right--kampanie-ig-bottom">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-4 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/kampanie-ig-small-img.png")}
                  alt=""
                />
              </div>
              <div className="col-lg-7 text-col">
                <h2 className="text-col__title mb-40">
                  Kampanie na Instagramie, <br />a kampanie na Facebooku
                </h2>
                <p className="text-col__desc">
                  Kampanie na Instagramie dzielą z kampaniami na Facebooku
                  również wspólne cele. Możemy więc prowadzić kampanię na
                  zasięg, konwersje czy sprzedaż z katalogu. Dostępna jest nawet
                  kampania mająca na celu generowanie leadów. Warto jednak
                  pamiętać, że na Instagramie nie przeprowadzimy niektórych
                  typów kampanii, między innymi promocji mającej na celu
                  pozyskanie reakcji na wydarzenia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-small single-offer-section-text-small--kampanie-na-ig">
        <div className="row">
          <div className="col-lg-6 offset-lg-4 text-col">
            <h2 className="text-col__title">
              Reklama to za mało? <br />
              Sprawdź propozycję na prowadzenie <br />
              konta Twojej marki na <span>Instagramie</span>
            </h2>
            <div className="text-wrapper--inner-action">
              <Link
                to="/prowadzenie-konta-instagram/"
                className="btn btn-line btn-download-portfolio"
              >
                prowadzenie konta na <strong>Instagramie</strong>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampanieNaInstagramiePage;
